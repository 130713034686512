@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* ===== GLOBAL STYLES ===== */
body {
  font-family: 'Courier New', monospace;
  background-color: #0f172a;
  color: #67e8f9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

html, body {
  /* Allow vertical scrolling but prevent pull-to-refresh */
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

/* ===== SCROLLING PANELS ===== */
.scrollable-panel {
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  padding-bottom: 20px;
}

.overflow-y-auto {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  overscroll-behavior: contain;
}

/* Keep controls non-scrollable */
.game-control, .click-area, button {
  touch-action: manipulation;
}

/* Allow text selection for input fields */
input, textarea {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* ===== SCROLLBAR STYLING ===== */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #1e293b;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0891b2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0e7490;
}

/* ===== BUTTON STYLING ===== */
button, 
.click-button {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  user-select: none;
  position: relative;
  overflow: hidden;
  transform: translateZ(0); /* Force hardware acceleration */
  transition: transform 0.1s, background-color 0.2s;
  -webkit-touch-callout: none;
  will-change: transform, background-color;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Button press effect - optimized for rapid clicking */
.click-button:active {
  transform: scale(0.98); /* Smaller scale change for faster recovery */
  transition: transform 0.05s; /* Faster transition */
  background-color: rgba(8, 145, 178, 0.8);
}

/* Add active states for better touch feedback */
.bg-cyan-800:active,
.bg-gray-700:active {
  filter: brightness(1.2);
  transition: filter 0.1s;
}

/* ===== ANIMATIONS ===== */
/* Progress bar animation */
@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.bg-cyan-600, .bg-green-600, .bg-blue-600, .bg-yellow-600, .bg-pink-600 {
  animation: pulse 2s infinite;
}

/* Ripple effect */
.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3); /* Lighter for better performance */
  transform: scale(0);
  animation: ripple-animation 0.4s linear; /* Shorter animation */
  pointer-events: none;
  will-change: transform, opacity; /* Hint for browser optimization */
}

@keyframes ripple-animation {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

/* Resource collection animation - optimized */
@keyframes float-up {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, -60px);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, -100px);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -120px);
  }
}

/* Create a container for popups to reduce DOM manipulations */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
}

/* ===== CARD STYLES ===== */
.border-cyan-900:hover {
  box-shadow: 0 0 10px rgba(8, 145, 178, 0.3);
}

/* ===== INPUT STYLING ===== */
input[type="number"] {
  background-color: #1e293b;
  border: 1px solid #0e7490;
  color: #f0fdfa;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
}

input[type="number"]:focus {
  outline: none;
  border-color: #06b6d4;
  box-shadow: 0 0 0 2px rgba(6, 182, 212, 0.2);
}

/* Remove arrows from number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* ===== CUSTOM CHECKBOX STYLING ===== */
.form-checkbox {
  appearance: none;
  background-color: #1e293b;
  border: 1px solid #0e7490;
  border-radius: 0.25rem;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.form-checkbox:checked {
  background-color: #06b6d4;
  border-color: #06b6d4;
}

.form-checkbox:checked::before {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 0.75rem;
  top: -0.1rem;
  left: 0.15rem;
}

/* ===== GAME PHASE-SPECIFIC STYLES ===== */
.phase-asteroid-mining {
  background: linear-gradient(to right, #0f172a, #1e293b);
}

.phase-solar-system {
  background: linear-gradient(to right, #0f172a, #164e63);
}

.phase-interstellar {
  background: linear-gradient(to right, #0f172a, #1e1b4b);
}

.phase-galactic-transcendence {
  background: linear-gradient(to right, #0f172a, #4a1d96);
}

/* ===== TEXT EFFECTS ===== */
.cosmic-text {
  background: linear-gradient(to right, #06b6d4, #8b5cf6, #ec4899);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: hue-rotate 10s infinite linear;
}

@keyframes hue-rotate {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

/* Text shadow for better visibility */
.text-shadow {
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
  will-change: transform, opacity;
}

/* ===== RESOURCE ICONS ===== */
.resource-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: middle;
}

.resource-minerals {
  background-color: #94a3b8;
  border-radius: 2px;
}

.resource-energy {
  background-color: #fde047;
  border-radius: 50%;
}

.resource-research {
  background-color: #60a5fa;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* ===== COLLAPSIBLE SECTIONS ===== */
.collapse-section {
  transition: all 0.3s ease-out;
  will-change: height, opacity;
}

.collapse-button {
  transition: all 0.2s ease;
}

.collapse-button:hover {
  background-color: rgba(8, 145, 178, 0.2);
}

/* ===== MOBILE-SPECIFIC STYLES ===== */
@media (max-width: 640px) {
  /* Create a proper scrolling container for mobile */
  body {
    height: auto;
    min-height: 100%;
  }
  
  /* Prevent scroll anchoring issues */
  .container {
    overflow-anchor: none;
  }
  
  /* Better padding for mobile */
  .container {
    padding-left: 8px;
    padding-right: 8px;
  }
  
  /* Larger touch targets */
  button {
    padding: 0.5rem;
    min-height: 40px;
  }
  
  /* Make scrollable areas more visible */
  .scrollable-panel {
    max-height: calc(100vh - 100px);
    position: relative;
    -webkit-overflow-scrolling: touch;
  }

  /* Stack grid layouts better on mobile */
  .grid-cols-4, .grid-cols-5, .grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  /* Compact panels on mobile */
  .bg-gray-800 {
    padding: 0.75rem;
  }
  
  /* Adjust vertical spacing */
  .space-y-4 > * {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }
  
  .space-y-3 > * {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
  
  /* Smaller margins between elements */
  .gap-4 {
    gap: 0.5rem;
  }
  
  /* Reduce padding in mobile view */
  .p-4 {
    padding: 0.75rem !important;
  }
  
  .p-3 {
    padding: 0.5rem !important;
  }
  
  /* Smaller gap in grid layouts */
  .gap-2 {
    gap: 0.375rem !important;
  }
  
  /* Smaller margins */
  .mb-4 {
    margin-bottom: 0.75rem !important;
  }
  
  .mb-3 {
    margin-bottom: 0.5rem !important;
  }
  
  .mt-4 {
    margin-top: 0.75rem !important;
  }
  
  .mt-3 {
    margin-top: 0.5rem !important;
  }
  
  /* Smaller font size for resource counts */
  .font-bold {
    font-size: 0.875rem;
  }
  
  /* Tiny descriptive text */
  .text-xs {
    font-size: 0.7rem !important;
  }
}